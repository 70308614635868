import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Routes } from './utils/Routes';
import Home from './pages/Home';
import About from './pages/About';
import Art from './pages/Art';
import Music from './pages/Music';
import Thoughts from './pages/Thoughts';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';

const router = createBrowserRouter([
  {
    path: Routes.Home,
    element: <Home />,
  },
  {
    path: Routes.About,
    element: <About />,
  },
  {
    path: Routes.Art,
    element: <Art />,
  },
  {
    path: Routes.Music,
    element: <Music />,
  },
  {
    path: Routes.Thoughts,
    element: <Thoughts />,
  },
  {
    path: Routes.Contact,
    element: <Contact />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
