import UnspokenArt from '../components/unspokenArt/UnspokenArt';

function Home() {
  return (
    <>
      <div className="App">
        <h2>Unspoken Creations</h2>
        <UnspokenArt />
      </div>
    </>
  );
}

export default Home;
