import MidnightThoughts from '../../assets/images/Art/MidnightThoughts/midnight_thoughts.png';

function UnspokenArt() {
  return (
    <div>
      <img
        src={MidnightThoughts}
        alt="MidnightThoughts Art Piece"
        style={{ width: '100%', maxWidth: '600px' }}
      />
    </div>
  );
}

export default UnspokenArt;
